<template>
  <span v-for="station in stations" v-bind:key="station">
    <router-link :to="{name: 'Station', params: {station: station}}">{{ station }}</router-link> |
  </span>
 
  <h1>{{ station }} / {{ formatDate(new Date()) }} </h1>
  <table v-if="items.length > 0">
    <tr>
      <th>
        Bestellnummer
      </th>
      <th>
        Bestellzeit
      </th>
      <th>
        Position
      </th>
      <th>
        Bezeichnung
      </th>
      <th>
        Produktcode
      </th>
      <th>
        Optionen
      </th>
    </tr>
    <tr v-for="item in items" v-bind:key="item.key">
      <td>
        {{ item.order_id }}
      </td>
      <td>
        {{ formatTime(item.received) }}
      </td>
      <td>
        {{ item.index }}
        von
        {{ item.max_index }}
      </td>
      <td>
        {{ item.name }}
      </td>
      <td>
        {{ item.code }}
      </td>
      <td>
          <div v-for="component in item.components" :key="component.id">
            {{ component.name }}
          </div>
      </td>
    </tr>
  </table>
  <div v-else>
    Es sind heute auf dieser Station keine Bestellungen eingegangen
  </div>
</template>

<script>
export default {
  name: 'Items',
  props: {
    station: String
  },
  computed: {
  stations () {
      let dishes = this.$store.state.dishes
      let stations = []
      for (let i=0; i < dishes[0].stations.length; i++) {
        stations.push(dishes[0].stations[i][1])
      }
      return stations
    },
    items () {
      var items = []
      for (var i = this.$store.state.orders.length - 1; i >= 0; i--) {
        var raw_order = this.$store.state.orders[i]
        var index = 0
        var max_index = 0
        for (var raw_item of raw_order.items) {
          max_index += raw_item.quantity
        }
        for (raw_item of raw_order.items) {
          console.log("F1", raw_item.station)
          console.log("F2", this.station)
          if (this.station != raw_item.station) {
            continue
          }
          for (var j = 0; j < raw_item.quantity; j++) {
            index += 1
            var item = {}
            item.received = raw_order.received
            item.order_id = raw_order.id
            item.index = index
            item.max_index = max_index
            item.code = raw_item.code
            item.name = raw_item.name
            item.station = raw_item.station
            item.key = [item.order_id, item.code, item.index]
            item.components = []
            for (var component of raw_item.components) {
              if (component.index == j)
                item.components.push(component)
            }
            items.push(item)
          }
        }
      }
      return items
    }
  }
}
</script>

