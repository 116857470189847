<template>
  <section class="items">
    <Items v-bind:station="station" />
  </section>
</template>

<script>
import Items from '@/components/Items.vue'

export default {
  name: 'ItemsView',
  components: {
    Items
  },
  computed: {
    station () {
      return this.$route.params.station
    }
  }
}
</script>

